form {
    h3 {
        span {        
            &.same-as-billing {
                font-weight: 400;
                color: @black;
                font-size: .8rem;
                input {
                    margin-left: 10px;
                }
            }
        }
    }
    label {
        font-weight: bold;
        font-size: .9rem;
        padding: 12px 12px 12px 0;
        display: inline-block;
        &.required {
            &:after {
                content: '*';
                color: @error;
                font-size: 1.5rem;
                position: absolute;
                margin-left: 5px;
            }
        }
    }
    input {
        &.search-field {
            margin: 0 5px;
            text-transform: uppercase;
            @media screen and (max-width: 600px) {
                margin: 0;
            }
        }
        &[type="text"], &[type="password"] {
            width: 100%;
            padding: 8px;
            border: 1px solid @bgtableheaderlight;
            box-sizing: border-box;
            resize: vertical;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
        }
        &[type="submit"] {
            padding: 12px 20px;
            border: none;
            cursor: pointer;
            float: right;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-top: 0;
            }
        }
        &:invalid {
            border: 2px solid @error;
            &:required {
                background-image: linear-gradient(to right, @bgtableheaderlight, @bgtableheaderlight);
            }
        }
        &:valid {
            border: 1px solid @bgtableheaderlight;
        }        
    }
    select {
        width: 100%;
        padding: 8px;
        border: 1px solid @bgtableheaderlight;
        box-sizing: border-box;
        resize: vertical;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        background: none;
        &:invalid {
            border: 2px solid @error;
            &:required {
                background-image: linear-gradient(to right, @bgtableheaderlight, @bgtableheaderlight);
            }
        }
        &:valid {
            border: 1px solid @bgtableheaderlight;
        }        
    }
    textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid @bgtableheaderlight;
        box-sizing: border-box;
        resize: vertical;
        height: 100px;
        &:invalid {
            border: 2px solid @error;
            &:required {
                background-image: linear-gradient(to right, @bgtableheaderlight, @bgtableheaderlight);
            }
        }
        &:valid {
            border: 1px solid @bgtableheaderlight;
        }        
    }
    .row {
        padding: 5px;
        .col-25 {
            float: left;
            width: 25%;
            margin-top: 6px;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-top: 0;
                padding: 0;
                margin-bottom: 5px;
            }
        }
        .col-33 {
            float: left;
            width: 32%;
            margin-top: 6px;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-top: 0;
                padding: 0;
                margin-bottom: 5px;
            }
        }
        .col-50 {
            float: left;
            width: 48%;
            margin-top: 6px;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-top: 0;
                padding: 0;
                margin-bottom: 5px;
            }
        }
        .col-75 {
            float: left;
            width: 75%;
            margin-top: 6px;
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-top: 0;
                padding: 0;
                margin-bottom: 5px;
            }
        }
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
    span {
        &.required {
            color: @error;
            font-size: 1.5rem;
            position: relative;
            top: 8px;
            font-weight: bold;
        }
    }
    &.quote-form {
        label {
            padding: 12px 12px 0 0;
        }
    }
    &.commission-form { 
        margin-bottom: 10px;
        .form-row {
            display: flex;
            justify-content: space-evenly;
            select {
                &.year {
                    width: 150px;
                }
                &.month {
                    width: 150px;
                }
            }
            a {
                margin-top: 10px;
            }
        }
    }
}
.ui-dialog {
    #applications_dialog {
        form {
            &#appsindsform {
                @media screen and (max-width: 600px) {
                    display: block;
                }
            }
        }
    }
}