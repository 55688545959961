.password-reset {
    div {
        &.password-reset {            
            width: 600px;
            background-color: @white;
            box-shadow: 0 0 9px 0 @boxshadow;
            margin: 100px auto;       
            text-align: center;     
            img {
                margin-top: 20px;
            }
            .password-check {
                div {
                    display: flex;
                    padding: 10px;
                    align-items: center;
                    font-weight: 500;
                    color: @error;
                }
                i {
                    height: 25px;
                    padding-right: 10px;
                }  
            }
            form {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding-top: 20px;
                .password-inputs {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: center;
                    padding: 10px 0;
                    label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 50px;
                        background-color: @mainblue;
                        color: @white;
                        font-weight: 500;
                        font-size: .8rem;
                        padding: 0;
                    }
                    input[type="text"], input[type="password"] {
                        width: 260px;
                        height: 50px;
                        border: 1px solid @tableheaderborder;
                        padding: 0 8px;
                        box-sizing: content-box;
                        resize: vertical;
                        border-bottom-right-radius: 3px;
                        border-bottom-left-radius: 3px;
                        border-top-right-radius: 3px;
                        border-top-left-radius: 3px;
                    }
                    i {           
                        margin-left: -40px;            
                       cursor: pointer;
                       padding: 15px;
                    }
                    span {
                        &#StrengthDisp, &#CompareDisp {
                            display: none;
                            font-weight: 900;
                        }                        
                    }
                }
                input[type="submit"] {
                    width: 100%;
                    padding: 15px;
                    margin-top: 20px;
                    background-color: @mainblue;
                    border: 0;
                    cursor: pointer;
                    font-weight: 700;
                    color: @white;
                    transition: background-color .2s;
                    font-size: 1rem;
                    &.disabled {
                        background-color: @tableheaderborder;
                        cursor: not-allowed;
                    }
                }                
                .errors {
                    display: none;
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                    padding: 10px;
                    color: @error;
                    background-color: @errorbg;
                }
            }
        }
    }
}
#forgot_password_dialog {
    text-align: center;
    label {
        background-color: @mainblue;
        color: @white;
        font-weight: 500;
        font-size: .8rem;
        padding: 18px;
        font-weight: bold;
    }
    input {
        width: 200px;
        height: 50px;
        border: 1px solid #ededed;
        margin-bottom: 20px;
        padding: 0 8px;
        box-sizing: content-box;
        background-image: linear-gradient(90deg,#ededed,#ededed);
    }
    button {
        margin: 20px 35%;
    }
}
